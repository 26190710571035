import _ from 'underscore';
import { convertHashPath } from 'components/shared/Link';
import { invariant } from 'util/invariant';

export const navigateTo = async (route: string) => {
  if (!_.isString(route)) {
    return;
  }
  const replacedRoute = convertHashPath(route);

  const { cvpartner } = window;

  invariant(cvpartner, 'cvpartner is not initialized');

  await cvpartner.navigate(replacedRoute);
};

export const redirectTo = (path: string) => {
  window.location.href = path;
};
