import type * as React from "react";
import { Timeout as ViewsSharedTimeout } from "auto-converted/views/shared/Timeout";
import { usePostponeLogout, useWillBeLoggedOut } from "stores/LoggedInStatus";

export const TimeoutWatcher: React.VFC = () => {
  const { data: willBeLoggedOut } = useWillBeLoggedOut();
  const postponeLogout = usePostponeLogout();

  if (!willBeLoggedOut) {
    return null;
  }

  return (
    <div id="timeout_modals">
      <ViewsSharedTimeout continue_callback={postponeLogout.mutate} />
    </div>
  );
};
