import * as Sentry from '@sentry/react';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import _ from 'underscore';
import { toIso } from 'auto-converted/lib/CountryLanguageUtils';
import type { Role, User } from 'schema/user';
import { http } from 'util/fetch';
import { redirectTo } from 'util/routes';
import { I18n } from 'util/translations';

// TODO: avoid using `Partial` here
export type CurrentUserType = Partial<User>;

class CurrentUserClass {
  // TODO: make `null` instead of `{}` by default
  current_user: CurrentUserType = {};
  private queued_user_updates: [
    user: CurrentUserType,
    callback: (() => void) | undefined,
  ][] = [];

  constructor() {
    makeObservable<
      CurrentUserClass,
      | 'queued_user_updates'
      | 'update'
      | 'update_debounced'
      | 'roles'
      | 'check_extra_role_permissions'
    >(this, {
      check_extra_role_permissions: false,
      hide_download: false,
      hide_preview: false,
      queued_user_updates: false,
      current_user: observable,
      reload: action,
      reload_if_not_loaded: action,
      toggle_expand_proposals: action,
      toggle_international_state: action,
      update_selected_office_ids: action,
      update_include_officeless_reference_projects: action,
      update_selected_tag_ids: action,
      update_preferred_download_format: action,
      update: false,
      update_debounced: false,
      update_country_and_office: action,
      is_loaded: computed,
      is_authenticated_by_password: computed,
      id: computed,
      name: computed,
      company_id: computed,
      country_id: computed,
      office_id: computed,
      roles: computed,
      is_internationalmanager: computed,
      is_countrymanager: computed,
      is_departmentmanager: computed,
      is_reference_manager: computed,
      is_limited_access_user: computed,
      is_consultant: computed,
      grantable_roles: computed,
      grantable_api_user_roles: computed,
      first_language: computed,
      other_languages: computed,
      preferred_language: computed,
      masterdata_languages: computed,
      language_code: computed,
      language_codes: computed,
      language_codes_iso: computed,
      ui_language_code: computed,
      ui_language_code_iso: computed,
      preferred_download_format: computed,
      country_code: computed,
      default_cv_id: computed,
      image: computed,
      displayable_roles: computed,
      displayable_roles_user_names: computed,
      international_toggle: computed,
      date_format: computed,
    });
  }

  async reload() {
    const url = '/api/v1/currentuser';
    const current_user = await http.get(url);
    if (current_user) {
      Sentry.setUser(current_user);
    }
    runInAction(() => {
      this.current_user = current_user as CurrentUserType;
    });
  }

  async reload_if_not_loaded() {
    if (this.is_loaded) {
      return;
    }

    await this.reload();
  }

  toggle_expand_proposals(new_state = false) {
    this.current_user.expand_proposals_toggle = new_state;
    return this.update({ expand_proposals_toggle: new_state });
  }

  toggle_international_state(state: 'expand' | 'hide') {
    if (this.current_user.international_toggle !== state) {
      this.current_user.international_toggle = state;
      return this.update({ international_toggle: state });
    }
  }

  update_selected_office_ids(new_selected_office_ids: readonly string[]) {
    this.current_user.selected_office_ids = new_selected_office_ids;
    return this.update({
      selected_office_ids: new_selected_office_ids,
    });
  }

  update_include_officeless_reference_projects(
    new_include_officeless_reference_projects: boolean,
  ) {
    this.current_user.include_officeless_reference_projects =
      new_include_officeless_reference_projects;
    return this.update({
      include_officeless_reference_projects:
        new_include_officeless_reference_projects,
    });
  }

  update_selected_tag_ids(new_selected_tag_ids: readonly string[]) {
    this.current_user.selected_tag_ids = new_selected_tag_ids;
    return this.update({ selected_tag_ids: new_selected_tag_ids });
  }

  update_preferred_download_format(preferred_download_format: string) {
    this.current_user.preferred_download_format = preferred_download_format;
    return this.update({
      preferred_download_format,
    });
  }

  private async update(attributes: CurrentUserType, callback?: () => void) {
    this.queued_user_updates.push([attributes, callback]);

    await this.update_debounced();
  }

  private update_debounced = _.debounce(async () => {
    const { user, callbacks } = this.queued_user_updates.reduce<{
      user: CurrentUserType;
      callbacks: (() => void)[];
    }>(
      (acc, [user, callback]) => {
        Object.assign(acc.user, user);
        if (callback) {
          acc.callbacks.push(callback);
        }

        return acc;
      },
      { user: {}, callbacks: [] },
    );

    // clear the queue
    this.queued_user_updates = [];

    const url = '/api/v1/currentuser';
    await http.put(url, { body: { user } });

    if (callbacks.length > 0) {
      await Promise.all(callbacks.map((callback) => callback()));
    }
  }, 100);

  update_country_and_office(
    attributes: CurrentUserType,
    redirect_to_path?: string,
  ) {
    const callback = () => {
      if (redirect_to_path) {
        return redirectTo(redirect_to_path);
      }
    };
    return this.update(attributes, callback);
  }

  get is_loaded() {
    return Object.keys(this.current_user).length > 0;
  }

  get is_authenticated_by_password() {
    return this.current_user.is_authenticated_by_password ?? false;
  }

  get id() {
    return this.current_user._id;
  }

  get name() {
    return this.current_user.name;
  }

  get company_id() {
    return this.current_user.company_id;
  }

  get country_id() {
    return this.current_user.country_id;
  }

  get office_id() {
    return this.current_user.office_id;
  }

  private get roles(): readonly Role[] {
    return this.current_user.roles ?? [];
  }

  get first_language(): string | undefined {
    return this.masterdata_languages[0];
  }

  get other_languages(): readonly string[] {
    return this.masterdata_languages.slice(1);
  }

  get preferred_language() {
    return this.first_language || 'int';
  }

  get masterdata_languages(): readonly string[] {
    return this.current_user.masterdata_languages ?? [];
  }

  get is_internationalmanager() {
    return this.roles.includes('internationalmanager');
  }

  get is_countrymanager() {
    if (this.is_internationalmanager) {
      return false;
    }

    return this.roles.includes('countrymanager');
  }

  get is_departmentmanager() {
    return this.roles.includes('departmentmanager');
  }

  get is_reference_manager() {
    return this.roles.includes('referencemanager');
  }

  get is_limited_access_user() {
    return this.roles.includes('limited_access');
  }

  get is_consultant() {
    return this.roles.includes('consultant');
  }

  get grantable_roles(): readonly Role[] {
    if (this.is_countrymanager) {
      return ['external', 'limited_access', 'consultant', 'countrymanager'];
    }
    if (this.is_internationalmanager) {
      return [
        'external',
        'limited_access',
        'consultant',
        'countrymanager',
        'referencemanager',
        'internationalmanager',
        'departmentmanager',
      ];
    }
    return [];
  }

  get grantable_api_user_roles(): readonly Role[] {
    if (this.is_countrymanager) {
      return ['consultant', 'countrymanager'];
    }
    if (this.is_internationalmanager) {
      return ['consultant', 'countrymanager', 'internationalmanager'];
    }
    return [];
  }

  get ui_language_code() {
    return this.current_user.ui_language_code || 'int';
  }

  get ui_language_code_iso() {
    return toIso(this.ui_language_code);
  }

  get language_code() {
    return this.current_user.language_code || 'int';
  }

  get language_codes() {
    return this.current_user.language_codes || [];
  }

  get language_codes_iso() {
    return this.language_codes.map(toIso);
  }

  get preferred_download_format() {
    return this.current_user.preferred_download_format;
  }

  get country_code() {
    return this.current_user.country_code;
  }

  private check_extra_role_permissions(
    office_id: string,
    custom_tag_ids: readonly string[],
    country_id: string | null,
  ) {
    const allowed_office_ids = this.current_user.role_allowed_office_ids;

    // Department manager of office
    if (allowed_office_ids?.includes(office_id)) {
      return false;
    }

    const allowed_custom_tag_ids = this.current_user.role_allowed_tag_ids;

    // Department manager of custom tag
    if (
      allowed_custom_tag_ids?.some((value) => custom_tag_ids.includes(value))
    ) {
      return false;
    }

    // Country manager of country
    if (this.is_countrymanager && this.country_id == country_id) {
      return false;
    }

    return true;
  }

  hide_download(
    user_id: string,
    country_id: string | null,
    office_id: string,
    custom_tag_ids: readonly string[],
  ) {
    if (!this.is_limited_access_user || user_id == this.id) {
      return false;
    }

    return this.check_extra_role_permissions(
      office_id,
      custom_tag_ids,
      country_id,
    );
  }

  hide_preview(
    user_id: string,
    country_id: string | null,
    office_id: string,
    custom_tag_ids: readonly string[],
  ) {
    if (!this.is_limited_access_user || user_id == this.id) {
      return false;
    }

    return this.check_extra_role_permissions(
      office_id,
      custom_tag_ids,
      country_id,
    );
  }

  get default_cv_id() {
    return this.current_user?.default_cv_id ?? null;
  }

  get image() {
    return this.current_user?.image ?? null;
  }

  get displayable_roles() {
    return displayable_roles(this.roles);
  }

  get displayable_roles_user_names() {
    return this.displayable_roles
      .map((role) => I18n.t(`role_labels.${role}`))
      .join(', ');
  }

  get international_toggle() {
    return this.current_user.international_toggle ?? 'hide';
  }

  get date_format() {
    return new Intl.DateTimeFormat(this.ui_language_code_iso, {
      dateStyle: 'medium',
    });
  }
}

export const CurrentUser = new CurrentUserClass();

/**
 * Modify an array of roles into an array of roles that needs to be shown to users
 */
export function displayable_roles(roles: readonly Role[]): readonly Role[] {
  if (roles.length === 1) {
    return roles;
  }
  if (roles.includes('internationalmanager')) {
    return ['internationalmanager'];
  }
  return roles.filter(
    (role) => role !== 'consultant' && role !== 'internationalmanager',
  );
}
