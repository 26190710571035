import Reflux from "reflux";

export const MasterdatasActions = Reflux.createActions([
  "reload_for",
  "load_more_for",
  "delete",
  "create",
  "update",
  "merge",
]);
export const CustomTagsActions = Reflux.createActions([
  "reload_for",
  "load_more_for",
  "delete",
  "create",
  "update",
]);
export const UnapprovedActions = Reflux.createActions([
  "recently_approved",
  "reload_for",
  "load_more_for",
  "delete",
  "delete_complete",
  "done_translating",
  "rename",
  "rename_complete",
]);
export const SelectedTemplatePathActions = Reflux.createActions([
  "select",
  "deselect",
]);
export const SelectedTemplateLocksPathActions = Reflux.createActions([
  "select",
  "deselect",
]);
export const SelectedTemplateMergeCellsPathActions = Reflux.createActions([
  "select",
  "deselect",
]);
export const SelectedForMergeActions = Reflux.createActions([
  "select",
  "deselect_all",
]);
export const SelectedMergeItemActions = Reflux.createActions([
  "select",
  "deselect",
  "deselect_all",
]);
export const CvRoleSelectionActions = Reflux.createActions([
  "reselect",
  "deselect_all",
  "toggleRoleFromProjectExperience",
  "toggleHighlightedRole",
]);
export const SelectTechnologyCategoriesActions = Reflux.createActions([
  "push",
  "select_categories_for",
]);
export const NumberOfUsersActions = Reflux.createActions([
  "reload_for",
  "reload_for_project_experiences_description",
]);
export const CustomersActions = Reflux.createActions([
  "reload_for",
  "reload_customer_for",
  "delete",
  "add_custom_tag",
  "delete_custom_tag",
  "create",
  "update_field",
  "replace_description",
  "change_industry",
  "merge",
  "load_more_for",
]);
export const CustomerSuggestionsActions = Reflux.createActions(["reload_for"]);
export const ProjectsActions = Reflux.createActions([
  "reload_for",
  "reload_project_for",
  "create",
  "update_field",
  "replace_description",
  "add_consultants",
  "add_project_owners",
  "delete",
  "delete_project_owner",
  "add_custom_tag",
  "delete_custom_tag",
  "add_office",
  "delete_office",
  "merge",
  "delete_image",
  "delete_attachment",
  "add_new_company_project_skill",
  "reload_custom_field_for",
  "add_new_custom_field",
  "update_custom_field",
  "delete_custom_field",
  "reorder_images",
  "update_image",
]);
export const ReferenceProjectsActions = Reflux.createActions([
  "reload_for",
  "create_and_append_skill",
  "update_skill",
  "delete_skill",
  "reload_custom_field_for",
  "update_field",
  "update_custom_field",
  "update_customer_contact",
  "delete_image",
  "reorder_images",
  "update_image",
]);
export const ProjectSuggestionsActions = Reflux.createActions(["reload_for"]);
export const CvsNameSearchResultsActions = Reflux.createActions([
  "reload_for",
  "load_more_for",
]);
export const UserValidationActions = Reflux.createActions(["validate"]);
export const CountriesActions = Reflux.createActions([
  "reload",
  "reload_allowed",
  "update_template",
  "update_office_template",
  "create_office",
  "update_office",
  "delete_office",
  "create_country",
  "update_country",
  "delete_country",
]);
export const CollaboratorsActions = Reflux.createActions([
  "reload_for",
  "add_user",
  "remove_user",
  "add_api_user",
  "remove_api_user",
  "create_api_key",
]);
export const ExternalCollaborationsActions = Reflux.createActions([
  "reload_for",
  "add_to",
  "remove_from",
]);
export const TemplatesActions = Reflux.createActions([
  "reload",
  "get",
  "update",
  "delete",
]);
export const TemplatesIndesignActions = Reflux.createActions([
  "reload",
  "get",
  "update",
  "delete",
]);
export const DateFormatsActions = Reflux.createActions(["reload", "update"]);
export const TodaysDateLabelsActions = Reflux.createActions([
  "reload",
  "update",
]);
export const BulkUploadsActions = Reflux.createActions([
  "create",
  "reload",
  "reload_bulk_upload",
]);
export const TemplatesV2Actions = Reflux.createActions([
  "reload",
  "reload_template",
  "create_template",
  "duplicate_template",
  "set_template_as_default",
  "delete",
  "save_template",
  "create_label",
  "update_label",
  "delete_label",
  "select_label_for",
  "select_static_image_for",
  "select_dynamic_image_for",
  "select_dynamic_image_index_for",
  "remove_object",
  "add_dependency",
  "remove_dependency",
  "remove_all_dependencies",
  "add_cell_merge",
  "remove_cell_merge",
  "remove_all_cell_merges",
  "append_table",
  "append_image",
  "append_static_text",
  "append_dynamic_text",
  "update_attributes",
  "update_style",
  "update_table_size",
  "append_line",
  "append_list",
  "append_shape",
  "append_slide",
  "delete_slide",
  "update_shape",
  "insert_object",
  "update_scope",
  "update_scope_filter",
  "newSection",
  "removeSection",
  "updateSectionBreak",
  "append_column_break",
  "rename_template",
]);
export const ExactMatchingCustomersActions = Reflux.createActions([
  "reload_for",
]);
export const ExactMatchingProjectsActions = Reflux.createActions([
  "reload_for",
]);
export const ExtraInfosActions = Reflux.createActions(["add", "remove"]);
export const MatchingCustomersActions = Reflux.createActions(["reload_for"]);
export const MatchingProjectsActions = Reflux.createActions(["reload_for"]);
export const CvsInProposalsActions = Reflux.createActions([
  "reload_for",
  "add_cv_to",
  "remove_cv_from",
  "reorder",
]);
export const ReferencesInProposalsActions = Reflux.createActions([
  "reload_for",
  "add_reference_to",
  "remove_reference_from",
  "reorder",
]);
export const CvsSearchResultsActions = Reflux.createActions([
  "reload_for",
  "load_more_for",
]);
export const CvsSearchResultsForCompanyInGroupActions = Reflux.createActions([
  "reload_for",
  "load_more_for",
]);
export const CvsNameSearchResultsForCompanyInGroupActions =
  Reflux.createActions(["reload_for", "load_more_for"]);
export const CvCountForCompanyGroupActions = Reflux.createActions([
  "reload_for",
]);
export const CvNameCountForCompanyGroupActions = Reflux.createActions([
  "reload_for",
]);
export const ReferencesSearchResultsActions = Reflux.createActions([
  "reload_for",
  "load_more_for",
]);
export const ReferencesSearchResultsForCompanyInGroupActions =
  Reflux.createActions(["reload_for", "load_more_for"]);
export const ReferenceCountForCompanyGroupActions = Reflux.createActions([
  "reload_for",
]);
export const CvsSearchSuggestionsActions = Reflux.createActions(["reload_for"]);
export const ReferencesSearchSuggestionsActions = Reflux.createActions([
  "reload_for",
]);
export const CvsActions = Reflux.createActions([
  "reload_for",
  "reload_image_for",
  "reload_section_for",
  "reload_subsection_for",
  "create_and_prepend_section",
  "create_and_append_section",
  "create_and_prepend_subsection",
  "create_and_append_subsection",
  "create_and_append_project_experience_skills",
  "create_and_append_technology_skills",
  "create_and_append_technology_skill_to_category",
  "convert_section",
  "duplicate_section_to_another_section",
  "add_custom_tag",
  "delete_custom_tag",
  "update_section_order",
  "update_subsection_order",
  "update_section_order_by_dates",
  "bulk_save_section_field",
  "delete_section",
  "delete_subsection",
  "save_field",
  "save_section_field",
  "save_subsection_field",
  "save_section_fields",
  "enable_only_section",
  "copy_to_master",
  "merge_section_to_master",
  "move_subsection",
  "move_subsection_to_new_section",
  "delete_section_image",
  "reorder_section_images",
  "update_section_image",
  "delete_section_attachment",
  "reload_cv_roles_for",
  "highlight_role",
  "highlight_role_by_name",
  "unhighlight_role",
  "rename_cv_role",
  "update_cv_role",
  "merge_cv_roles",
  "update_skill_or_remake_from_masterdata",
]);
export const DropboxCredentialsActions = Reflux.createActions(["reload"]);
export const SftpCredentialsActions = Reflux.createActions([
  "reload",
  "update",
]);
export const CvUpdateTaskActions = Reflux.createActions([
  "create",
  "reload_for",
  "start_polling",
  "clear_all",
]);
export const TermsForDeletionActions = Reflux.createActions([
  "reload_for",
  "create_for",
  "clear_all",
]);
export const ProposalsActions = Reflux.createActions([
  "reload_for",
  "update",
  "create_for",
  "reload_proposal",
  "change_owner",
]);
export const CustomerDescriptionsActions = Reflux.createActions([
  "reload_for",
  "change_description",
]);
export const ProjectDescriptionsActions = Reflux.createActions([
  "reload_for",
  "change_description",
]);
export const ProjectFieldOverviewActions = Reflux.createActions([
  "reload_for",
  "synchronise_for",
]);
export const PusherActions = Reflux.createActions([
  "subscribe",
  "subscribe_company_customer",
  "subscribe_proposal",
  "subscribe_reference_project",
  "subscribe_download_file",
]);
